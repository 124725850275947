/*
 * @Date: 2023-09-26 14:39:57
 * @LastEditors: Sanmuly
 * @LastEditTime: 2023-09-26 17:22:25
 * @GitHub: https://github.com/Sanmuly
 */
import Vue from 'vue'
import App from './App.vue'
import {
    Button,
    Card,
    Cell,
    Divider,
    Grid,
    GridItem,
    Icon,
    Swipe,
    SwipeCell,
    SwipeItem,
    Form,
    Field,
    Collapse, CollapseItem, Tabbar, TabbarItem
} from 'vant'
import router from './router'

Vue.config.productionTip = false

Vue.use(Button)
    .use(Grid)
    .use(GridItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(SwipeCell)
    .use(Cell)
    .use(Card)
    .use(Divider)
    .use(Icon)
    .use(Form)
    .use(Field).use(Collapse).use(CollapseItem).use(Tabbar).use(TabbarItem)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
