<template>
  <div style="height: 100%;background-color: white">
    <!--表单区域-->
    <div class="my-form">
      <div class="my-title">用户信息</div>
      <van-field
          class="icon-color1"
          v-model="number"
          name="个人编号"
          label="个人编号"
          left-icon="ecard-pay"
          placeholder="个人编号"
          readonly
      />
      <div class="my-title">房间信息</div>
      <van-field
          class="icon-color2"
          v-model="areaSelected"
          name="区域"
          label="区域"
          left-icon="location-o"
          placeholder="点击选择区域"
          role="button"
          tabindex="0"
          readonly
      />
      <van-field
          class="icon-color2"
          v-model="buildingSelected"
          name="楼号"
          label="楼号"
          left-icon="hotel-o"
          placeholder="点击选择楼号"
          role="button"
          tabindex="0"
          readonly
      />
      <van-field
          class="icon-color2"
          v-model="floorSelected"
          name="楼层"
          label="楼层"
          left-icon="bar-chart-o"
          placeholder="点击选择楼层"
          role="button"
          tabindex="0"
          readonly
      />
      <van-field
          class="icon-color2"
          v-model="roomSelected"
          name="房间"
          label="房间"
          left-icon="wap-home-o"
          placeholder="点击选择房间"
          role="button"
          tabindex="0"
          readonly
      />

      <div class="my-title">缴费信息</div>
      <van-field
          class="icon-color3"
          v-model="payNumber"
          name="缴费金额"
          label="缴费金额"
          left-icon="gold-coin-o"
          placeholder="元,最大金额为200元"
          disabled
      />

      <van-field
          class="icon-color3"
          v-model="password"
          name="账户密码"
          label="账户密码"
          left-icon="shield-o"
          placeholder="6位数字密码"
          right-icon="closed-eye"
          disabled
      />

      <div class="btn-div">
        <van-button
            type="primary"
            size="normal"
            disabled
            block
        >缴 费
        </van-button>
        <div style="font-size: 12px; color: darkgrey; margin-top: 12px;">
          提示:剩余电量会定期刷新,因此缴费成功后在本页面可能无法及时的查询到最新的剩余电量,请您谅解。
        </div>
      </div>

      <van-tabbar v-model="active" active-color="rgb(7, 205, 96)" inactive-color="#000">
        <van-tabbar-item icon="cash-on-deliver">电控缴费</van-tabbar-item>
        <van-tabbar-item icon="comment-o">缴费记录</van-tabbar-item>
      </van-tabbar>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BalanceView',
  data: function () {
    return {
      name: "王森",
      number: "202009230324",
      balance: "11.55",
      areaList: [
        "第一生活区",
        "第二生活区",
        "沂水校区"
      ],
      BuildingList: [[
        "B1",
        "B2",
        "B3",
        "B4",
        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "D1",
        "D2",
        "D3",
        "D4",
        "D5",
        "D6",
        "D7",
        "F1",
        "F2",
        "F3",
        "F4"
      ]],
      areaSelected: "", buildingSelected: "", floorSelected: "", roomSelected: "", payNumber: "", password: "",
      active: 0
    };
  },
  mounted() {
    // document.querySelector("body").style.backgroundColor = "#fff"
  }
}
</script>

<style scoped>

html, body {
  /*overflow-x: hidden;*/
//background-image: url('https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.616pic.com%2Fbg_w1180%2F00%2F09%2F52%2FG0g9Fq80aw.jpg&refer=http%3A%2F%2Fpic.616pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637806690&t=e3bc7bf0eb1eedad02d25756cd3b008a'); background-repeat: no-repeat;
  background-size: 100% 100%;
}


.my-form {
  width: 100%;
  text-align: left;
  font-size: 18px;
  background-color: #fff;
}

.my-title {
  margin-left: 4%;
  text-align: left;
  font-size: 14px;
  color: silver;
  padding-top: 5px;
}

.icon-color1 {
  color: #07cd60;
}

.icon-color2 {
  color: #00bfff;
}

.icon-color3 {
  color: #ff8c00;
}

.btn-div {
  width: 90%;
  margin-left: 5%;
  padding: 20px 0;
}

</style>
