import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '../views/HomeView.vue'
import BalanceView from '../views/BalanceView.vue'
import ChangePwdView from '../views/ChangePwdView.vue'
import QuestionView from '../views/QuestionView.vue'
import UnBindView from '../views/UnBindView.vue'
import ElectricityView from '../views/ElectricityView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {title: '临大E码通'},
        component: HomeView
    },
    {
        path: '/balance',
        name: 'balance',
        meta: {title: '账户余额'},
        component: BalanceView
    },
    {
        path: '/changePwd',
        name: 'changePwd',
        meta: {title: '密码修改'},
        component: ChangePwdView
    },
    {
        path: '/UnBind',
        name: 'UnBind',
        meta: {title: '解除绑定'},
        component: UnBindView
    },
    {
        path: '/question',
        name: 'question',
        meta: {title: '常见问题'},
        component: QuestionView
    },
    {
        path: '/electricity',
        name: 'electricity',
        meta: {title: '电控缴费'},
        component: ElectricityView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


export default router
